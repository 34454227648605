import React, { Component, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

export const Map = props => {
  const { data } = props;
  const [selectedProperty, setSelectedProperty] = useState(null);

  return (
    <LoadScript id="script-loader" googleMapsApiKey="AIzaSyDLstoAHPeWcCdiPvNU7N1-jTcMZhUv-ZQ">
      <GoogleMap
        id="example-map"
        mapContainerStyle={{
          height: '800px',
          width: '1000px',
        }}
        zoom={14}
        center={{
          lat: 43.0831,
          lng: -73.7846,
        }}
      >
        {data.map((address: any, index: number) => (
          <Marker
            key={index + 'marker'}
            position={{
              lat: Number(address.lat),
              lng: Number(address.long),
            }}
            onClick={() => {
              setSelectedProperty(address);
            }}
          />
        ))}

        {selectedProperty && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedProperty(null);
            }}
            position={{
              lat: Number(selectedProperty.lat),
              lng: Number(selectedProperty.long),
            }}
          >
            <div>
              <h2>{selectedProperty.street_address}</h2>
              <p>{'Units:' + selectedProperty.total_units}</p>
              <p>{'Market value:' + selectedProperty.market_value_total}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};
