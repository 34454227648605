import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AllCategoryTemplate from '../../templates/AllCategory';
import { useIdentityContext } from 'react-netlify-identity-widget';
import { navigate } from '@reach/router';

export const mainListItems = (setView: any) => {
  return (
    <div>
      <ListItem
        button
        onClick={() => {
          setView('operatingData');
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          setView('profile');
        }}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          setView('marketing');
        }}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Marketing" />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          setView('map');
        }}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Map" />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          setView('letters');
        }}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Letters" />
      </ListItem>
    </div>
  );
};

export const secondaryListItems = () => {
  const { user, isLoggedIn, logoutUser } = useIdentityContext();
  return (
    <div>
      <ListSubheader inset>Settings</ListSubheader>

      <ListItem
        button
        onClick={() => {
          logoutUser();
          navigate('/');
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </ListItem>
    </div>
  );
};
