export const newPruynOperatingData = [
  { month: 'Feb, 2019', income: 10108, expenses: 6057, mortgage: 6850 },
  { month: 'Mar, 2019', income: 10280, expenses: 6519, mortgage: 6850 },
  { month: 'Apr, 2019', income: 10605, expenses: 3380, mortgage: 6850 },
  { month: 'May, 2019', income: 8720, expenses: 4235, mortgage: 6850 },
  { month: 'Jun, 2019', income: 11340, expenses: 1549, mortgage: 6850 },
  { month: 'Jul, 2019', income: 11625, expenses: 3545, mortgage: 6850 },
  { month: 'Aug, 2019', income: 10805, expenses: 2315, mortgage: 6850 },
  { month: 'Sep, 2019', income: 13408, expenses: 2746, mortgage: 7638 },
  { month: 'Oct, 2019', income: 12065, expenses: 10015, mortgage: 7638 },
  { month: 'Nov, 2019', income: 10454, expenses: 2964, mortgage: 7638 },
];
