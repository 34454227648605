import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import Noi from './Noi';
import NetWorth from './NetWorth';
import CashFlow from './CashFlow';
import { useIdentityContext } from 'react-netlify-identity';
import Profile from '../../pages/app/profile';
import Marketing from './Marketing';
import { getAirtableData, IfilterParams } from '../../services/airtable';
import { Map } from './Map';
import _ from 'lodash';
import { SendLetters } from './SendLetters';

const drawerWidth = 240;

export default function DashboardComponent() {
  const identity = useIdentityContext();
  let fullName;
  if (identity && identity.user && identity.user.user_metadata) {
    fullName = identity.user.user_metadata.full_name;
  }

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [view, setView] = useState('marketing');

  const [propertyData, setPropertyData] = useState([]);
  const [saratogaPropertyData, setSaratogaPropertyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zipCode, setZipCode] = useState();

  const [units, setUnits] = useState(0);

  const getData = async () => {
    const data = await getAirtableData('All_reonomy_data');
    const saratoga = await getAirtableData('Saratoga');
    setPropertyData(data);
    setSaratogaPropertyData(saratoga);
  };

  const parseData = () => {
    let data: any = JSON.parse(localStorage.getItem('data'));
    if (data && zipCode) {
      data = _.filter(data, { zip_code: zipCode });
    }
    if (data && units) {
      data = _.filter(data, function(x) {
        return x.total_units > units;
      });
    }
    setPropertyData(data);
  };

  useEffect(() => {
    setLoading(true);
    parseData();

    return setLoading(false);
  }, [zipCode, units]);

  useEffect(() => {
    const getAndParseData = async () => {
      setLoading(true);
      await getData();
      setLoading(false);
    };
    getAndParseData();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        {view === 'operatingData' && (
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Welcome, {fullName}
            </Typography>
          </Toolbar>
        )}
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems(setView)}</List>
        <Divider />
        <List>{secondaryListItems()}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {view === 'operatingData' && (
            <Grid container spacing={3}>
              <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                New Pruyn St LLC Net Worth and Operating Data
              </Typography>
              {/* Recent NetWorth */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <NetWorth />
                </Paper>
              </Grid>
              {/* Noi */}
              <Grid item xs={12}>
                <Paper className={fixedHeightPaper}>
                  <Noi />
                </Paper>
              </Grid>

              {/* Recent CashFlow */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <CashFlow />
                </Paper>
              </Grid>
            </Grid>
          )}
          {view === 'profile' && <Profile />}
          {view === 'marketing' && (
            <Marketing
              propertyData={propertyData}
              setZipCode={setZipCode}
              zipCode={zipCode}
              parseData={parseData}
              loading={loading}
              units={units}
              setUnits={setUnits}
            />
          )}
          {view === 'map' && <Map data={saratogaPropertyData} />}
          {view === 'letters' && <SendLetters />}
        </Container>
      </main>
    </div>
  );
}

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
