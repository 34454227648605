import * as React from 'react';
import { useEffect, useState } from 'react';
import { Content, Layout, Wrapper, SectionTitle } from '../../components';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormGroup,
  FormControlLabel,
  makeStyles,
  Grid,
  Typography,
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  IconButton,
  Input,
  Divider,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { parseAddress, sendMultipleLetters } from '../../utils/dataParsing';
import PropertyItem from './PropertyItem';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function Marketing(props: any) {
  const data = props.propertyData;
  const { parseData, zipCode, setZipCode, loading, units, setUnits } = props;

  const classes = useStyles();
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [saratogaFilter, setSaratogaFilter] = useState(false);
  const [glensFallsFilter, setGlensFallsFilter] = useState(false);
  const [ballstonSpaFilter, setBallstonSpaFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dataWithParsedAddress, setDataWithParsedAddress] = useState();
  const [isLive, setIsLive] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleClickOpen = () => {
    setDialogLoading(true);
    setOpen(true);
    setDialogLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <Content>
        <div className={classes.root}>
          <Typography variant="h5">View</Typography>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={dense} onChange={event => setDense(event.target.checked)} value="dense" />}
              label="Enable dense"
            />
            <FormControlLabel
              control={<Checkbox checked={secondary} onChange={event => setSecondary(event.target.checked)} value="secondary" />}
              label="Show mailing address"
            />
          </FormGroup>
          <Typography variant="h5">Location</Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saratogaFilter}
                  onChange={event => {
                    if (event.target.checked) {
                      setZipCode('12866');
                      setSaratogaFilter(true);
                      setBallstonSpaFilter(false);
                      setGlensFallsFilter(false);
                    } else {
                      setZipCode('');
                      setSaratogaFilter(false);
                    }
                  }}
                  value="secondary"
                />
              }
              label="Saratoga"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={glensFallsFilter}
                  onChange={event => {
                    if (event.target.checked) {
                      setZipCode('12801');
                      setGlensFallsFilter(true);
                      setBallstonSpaFilter(false);
                      setSaratogaFilter(false);
                    } else {
                      setZipCode();
                      setGlensFallsFilter(false);
                    }
                  }}
                  value="secondary"
                />
              }
              label="Glens Falls"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ballstonSpaFilter}
                  onChange={event => {
                    if (event.target.checked) {
                      setZipCode('12020');
                      setBallstonSpaFilter(true);
                      setGlensFallsFilter(false);
                      setSaratogaFilter(false);
                    } else {
                      setZipCode('');
                      setBallstonSpaFilter(false);
                    }
                  }}
                  value="secondary"
                />
              }
              label="Ballston Spa"
            />
          </FormGroup>
          <Typography variant="h5">Number of units</Typography>
          <Typography variant="h6">Greater than:</Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={units === 4}
                  onChange={event => {
                    if (event.target.checked) {
                      setUnits(4);
                    } else {
                      setUnits(0);
                    }
                  }}
                  value="4"
                />
              }
              label="4"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={units === 10}
                  onChange={event => {
                    if (event.target.checked) {
                      setUnits(10);
                    } else {
                      setUnits(0);
                    }
                  }}
                  value="10"
                />
              }
              label="10"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={units === 20}
                  onChange={event => {
                    if (event.target.checked) {
                      setUnits(20);
                    } else {
                      setUnits(0);
                    }
                  }}
                  value="20"
                />
              }
              label="20"
            />
          </FormGroup>
          <Typography variant="h5">Send mailer to these owners:</Typography>
          <FormGroup row>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClickOpen();
                const dataWithParsedAddress = selectedData.map((item: any, index: any) => {
                  if (item.mailing_address) {
                    item['mailing_address'] = parseAddress(item.mailing_address);
                  }
                  return { ...item };
                });

                setDataWithParsedAddress(dataWithParsedAddress);
              }}
              disabled={dialogLoading}
            >
              Send letters
            </Button>

            {data && (
              <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  Confirm send
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you want to send letter to these {data.length} owners?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      const responseData = await sendMultipleLetters(dataWithParsedAddress, isLive);

                      setResponseData(responseData);
                      alert('Letters sent');
                      handleClose();
                    }}
                    color="primary"
                  >
                    Confirm send
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={isLive} onChange={event => setIsLive(event.target.checked)} value="dense" />}
              label="Send live letter?"
            />
          </FormGroup>
          {/* {responseData && (
            <FormGroup row>
              <Button
                onClick={() => {
                  setResponseDialogOpen(true);
                }}
                color="primary"
              >
                View response data
              </Button>
              <Dialog open={responseDialogOpen} onClose={() => setResponseDialogOpen(false)}>
                <DialogTitle id="draggable-dialog-title">Lob response data</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {responseData.map(response => {
                      if (response.id) {
                        return <p>{response.id}</p>;
                      } else {
                        console.log(response);
                        return <p>{response} </p>;
                      }
                    })}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setResponseDialogOpen(false)} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </FormGroup>
          )} */}

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Property List
              </Typography>
              <Typography variant="subtitle1">Number of results: {data ? data.length : '0'}</Typography>
              {loading && <CircularProgress />}
              <Typography variant="subtitle1" style={{ float: 'right' }}>
                Select all
              </Typography>
              <Checkbox
                style={{ float: 'right' }}
                onClick={e => {
                  if (e.target.checked) {
                    setSelectedData(data);
                  } else {
                    setSelectedData([]);
                  }
                }}
              />
            </Grid>
          </Grid>
          <List dense={dense}>
            {data && <PropertyItem data={data} secondary={secondary} setSelectedData={setSelectedData} selectedData={selectedData} />}
          </List>
        </div>
      </Content>
    </Layout>
  );
}
