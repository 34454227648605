var Airtable = require('airtable');

Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'keyVeEqTdJQueB6IW',
});
var base = Airtable.base('appBaeXducdgSkynf');

export interface IfilterParams {
  units: number;
  zip: string;
}

export const getAirtableData = async (table: string) => {
  // const { units, zip } = params;
  const data: any = [];

  const fields = () => {
    if (table === 'Saratoga') {
      return [
        'APN',
        'street_address',
        'city',
        'total_units',
        'reported_owner',
        'zip_code',
        'mailing_address',
        'lat',
        'long',
        'market_value_total',
      ];
    } else {
      return ['APN', 'street_address', 'city', 'total_units', 'reported_owner', 'zip_code', 'mailing_address'];
    }
  };

  await base(table)
    .select({
      maxRecords: 1300,
      fields: fields(),
      // filterByFormula: `AND({total_units} = ${units}, {zip_code}=${zip})`,
      sort: [{ field: 'total_units', direction: 'desc' }],
    })
    .eachPage(
      function page(records: any, fetchNextPage: any) {
        // This function (`page`) will get called for each page of records.

        records.forEach((record: any) => {
          record.fields['id'] = record.id;
          data.push(record.fields);
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      },
      function done(err: any) {
        localStorage.setItem('data', JSON.stringify(data));
        if (err) {
          console.error(err);
          return;
        }
      },
    );

  return data;
};
