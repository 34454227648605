import LobRequests from '../services/lob';

export const formatNumberToCurrency = (number: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(number);
};

export const parseAddress = (address: string) => {
  let parsedAddress: any = {};

  try {
    const ownerAddressArray = address.split(',');
    const ownerStateAndZip = ownerAddressArray[2].split(' ');

    parsedAddress['ownersStreet'] = ownerAddressArray[0];
    parsedAddress['ownersCity'] = ownerAddressArray[1];
    parsedAddress['ownersState'] = ownerStateAndZip[1];
    parsedAddress['ownersZip'] = ownerStateAndZip[2];
  } catch {
    parsedAddress = null;
  }

  return parsedAddress;
};

export const sendMultipleLetters = async (data: any, isLive: boolean) => {
  const responseData: any = [];
  const errors: any = [];

  for (let i = 0; i < data.length; i++) {
    if (
      data[i] &&
      data[i].street_address &&
      data[i].reported_owner &&
      data[i].APN &&
      data[i].id &&
      data[i].mailing_address &&
      data[i].mailing_address.ownersStreet &&
      data[i].mailing_address.ownersCity &&
      data[i].mailing_address.ownersState &&
      data[i].mailing_address.ownersZip
    ) {
      const { street_address, reported_owner, APN, id } = data[i];
      const { ownersStreet, ownersCity, ownersState, ownersZip } = data[i].mailing_address;

      const addressData = {
        owner: reported_owner,
        ownerSentence: `Owner of ${street_address}`,
        ownersStreet,
        ownersCity,
        ownersState,
        ownersZip,
        APN,
        id,
        propertySentence: street_address,
        isLive,
      };

      await LobRequests.createLetter(addressData)
        .then(res => responseData.push(res))
        .catch(err => {
          errors.push(err);
        });
    } else {
      return;
    }
  }

  console.log(errors);
  return responseData;
};
