import React, { Component, useState } from 'react';
import moment from 'moment';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import LobRequests from '../../services/lob';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 400,
      },
    },
  }),
);

export const SendLetters = () => {
  const classes = useStyles();
  const [buildingStreet, setBuildingStreet] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownersStreet, setOwnersStreet] = useState('');
  const [ownersCity, setOwnersCity] = useState('');
  const [ownersState, setOwnersState] = useState('');
  const [ownersZip, setOwnersZip] = useState('');
  const [isLive, setIsLive] = useState(false);
  const [letterResponseData, setLetterResponseData] = useState(null);

  return (
    <div>
      <Typography variant="h5">Enter an address below to send a mailer</Typography>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={async e => {
          e.preventDefault();

          // send letter to netlify function lob endpoint
          const data = {
            owner: ownerName,
            ownerSentence: `Owner of ${buildingStreet}`,
            ownersStreet,
            ownersCity,
            ownersState,
            ownersZip,
            propertySentence: buildingStreet,
            isLive,
          };

          const response = await LobRequests.createLetter(data);
          setLetterResponseData(response);
          if (response.id) {
            alert(`${response.description} was sent`);
          } else {
            alert('Failed to send letter');
          }
        }}
      >
        {buildingStreet && (
          <Button
            onClick={() => {
              setBuildingStreet('');
              setOwnerName('');
              setOwnersStreet('');
              setOwnersCity('');
              setOwnersState('');
              setOwnersZip('');
              setLetterResponseData(null);
            }}
          >
            Reset Form
          </Button>
        )}
        <div>
          <TextField
            id="building-address"
            label="Build Street Address"
            variant="filled"
            value={buildingStreet}
            onChange={e => setBuildingStreet(e.target.value)}
          />
        </div>
        <div>
          <TextField id="owner-name" label="Owner Name" variant="filled" value={ownerName} onChange={e => setOwnerName(e.target.value)} />
        </div>
        <div>
          <TextField
            id="owner-street"
            label="Owner Street"
            variant="filled"
            value={ownersStreet}
            onChange={e => setOwnersStreet(e.target.value)}
          />
          <TextField
            id="owner-city"
            label="Owner City"
            variant="filled"
            value={ownersCity}
            onChange={e => {
              setOwnersCity(e.target.value);
            }}
          />
          <TextField
            id="owner-state"
            label="Owner State"
            variant="filled"
            value={ownersState}
            onChange={e => {
              setOwnersState(e.target.value);
            }}
          />
          <TextField
            id="owner-zip"
            label="Owner Zip"
            variant="filled"
            value={ownersZip}
            onChange={e => {
              setOwnersZip(e.target.value);
            }}
          />
        </div>
        <div>
          <FormControlLabel
            control={<Checkbox checked={isLive} onChange={event => setIsLive(event.target.checked)} value="dense" />}
            label="Send live letter?"
          />
          <Button variant="contained" color="primary" type="submit">
            Send letter
          </Button>
        </div>
      </form>
      {letterResponseData && (
        <a href={letterResponseData.url} target="_blank">
          Link to sent letter
        </a>
      )}
    </div>
  );
};
