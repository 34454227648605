import axios from 'axios';

const basePath = 'https://grassi.co/.netlify/functions';

class LobRequests {
  public static createLetter = async (data: any): Promise<any> => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const path = basePath + '/lob';
    const response = await axios
      .post(path, JSON.stringify(data), config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
    return response;
  };
}

export default LobRequests;
