import React from 'react';
import { Router, navigate } from '@reach/router';
import { Layout, NavBar } from '../components/index';

import Profile from './app/profile';
import Login from './app/login';
import { useIdentityContext } from 'react-netlify-identity-widget';
import DashboardComponent from '../components/Dashboard/Dashboard';

function PrivateRoute(props: any) {
  const { isLoggedIn } = useIdentityContext();
  const { component: Component, location, ...rest } = props;

  React.useEffect(() => {
    if (!isLoggedIn && location.pathname !== `/app/login`) {
      // If the user is not logged in, redirect to the login page.
      navigate(`/app/login`);
    }
  }, [isLoggedIn, location]);
  return isLoggedIn ? <Component {...rest} /> : null;
}
function PublicRoute(props: any) {
  return <div>{props.children}</div>;
}

export default function App() {
  return (
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/dashboard" component={DashboardComponent} />
      <PublicRoute path="/app">
        <PrivateRoute path="/" component={Profile} />
        <Login path="/login" />
      </PublicRoute>
    </Router>
  );
}
