import * as React from 'react';
import { Content, Header, Layout, Wrapper, SectionTitle } from '../../components';
import Helmet from 'react-helmet';
import config from '../../../config/SiteConfig';
import { Link } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';

export default function Profile() {
  const identity = useIdentityContext();
  return (
    <Layout>
      <Helmet title={`Profile`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
        <SectionTitle>Profile</SectionTitle>
      </Header>
      <Wrapper>
        <Content>
          <p>This is your profile</p>
          <pre>{JSON.stringify(identity, null, 2)}</pre>
        </Content>
      </Wrapper>
    </Layout>
  );
}
