import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatNumberToCurrency } from '../../utils/dataParsing';
import Paper from '@material-ui/core/Paper';

function preventDefault(event: any) {
  event.preventDefault();
}

const calcNetWorth = (
  mainValue: number,
  nineValue: number,
  mainMortgageBankBalance: number,
  mainMortgageSecondBalance: number,
  nineMortgageBalance: number,
  freeCash: number,
) => {
  const netWorth = formatNumberToCurrency(
    mainValue + nineValue - mainMortgageBankBalance - mainMortgageSecondBalance - nineMortgageBalance + freeCash,
  );
  const totalBuildingValue = formatNumberToCurrency(mainValue + nineValue);
  const totalMortgageBalance = formatNumberToCurrency(mainMortgageBankBalance + mainMortgageSecondBalance + nineMortgageBalance);

  return { netWorth, totalBuildingValue, totalMortgageBalance, freeCash };
};

const data = calcNetWorth(950000, 120000, 747907, 145000, 80000, 4000);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  depositContext: {
    flex: 1,
  },
  paper: {
    padding: 24,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function NetWorth() {
  const classes = useStyles();
  const currentDate = new Date();
  return (
    <React.Fragment>
      <Title>Current Net Worth</Title>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={8} sm={6} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Typography component="p" variant="h6">
              Total Net Worth
            </Typography>
            <Typography component="p" variant="h4">
              {data.netWorth}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
              as of 11/27/19
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={8} sm={6} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Typography component="p" variant="h6">
              Value of Buildings
            </Typography>
            <Typography component="p" variant="h4">
              {data.totalBuildingValue}
            </Typography>
          </Paper>
        </Grid>

        {/* Recent OperatingData */}
        <Grid item xs={8} sm={6} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Typography component="p" variant="h6">
              Total Mortgage Balance
            </Typography>
            <Typography component="p" variant="h4">
              {data.totalMortgageBalance}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
