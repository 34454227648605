import * as React from 'react';
import { useEffect, useState } from 'react';
import { ListItem, Grid, ListItemText, ListItemSecondaryAction, Checkbox, Divider } from '@material-ui/core';

export default function PropertyItem(props: any) {
  const { data, secondary, setSelectedData, selectedData } = props;

  const [checkedArray, setCheckedArray] = useState(Array.from(data, () => true));

  const toggleCheckbox = (index: number) => {
    checkedArray[index] = checkedArray[index] ? false : true;

    setCheckedArray(checkedArray);
  };

  const addRemoveFromSelectedData = (checked: boolean, item: any) => {
    // convert selectedData to array
    // if checked add to selected data
    if (checked && selectedData.length === 0) {
      setSelectedData([item]);
    } else if (checked && selectedData.length > 0) {
      setSelectedData(selectedData.concat([item]));
    } else if (!checked) {
      let newArray = selectedData;

      for (let i = 0; i < selectedData.length; i++) {
        if (item.id === selectedData[i].id) {
          console.log('found match');
          newArray.splice(i, 1);
          console.log(newArray);
          setSelectedData(newArray);
          return;
        }
      }
    }
  };

  return data.map((item: any, index: number) => {
    return (
      <div key={index + 'list-item'}>
        <ListItem key={index + 'mailing-address'}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ListItemText primary={item.street_address} secondary={secondary ? item.mailing_address : null} />
            </Grid>
            <Grid item xs={3}>
              <p>Units: {item.total_units}</p>
              <p>Owner: {item.reported_owner}</p>
            </Grid>
            <Grid item xs={3}>
              <ListItemSecondaryAction>
                <Checkbox
                  checked={checkedArray[index]}
                  value={checkedArray[index]}
                  onClick={e => {
                    addRemoveFromSelectedData(e.target.checked, item);
                    toggleCheckbox(index);
                  }}
                />
              </ListItemSecondaryAction>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
      </div>
    );
  });
}
