import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { BarChart, Bar, XAxis, YAxis, Label, ResponsiveContainer, LabelList, Legend } from 'recharts';
import Title from './Title';
import { newPruynOperatingData } from '../../data/propertyData';

const npData = newPruynOperatingData;

// Generate Sales Data
function createData(date: string, income: number, expenses: number) {
  const noi = income - expenses;
  return { date, income, expenses, noi };
}

const data = npData.map((item, index) => {
  return createData(item.month, item.income, item.expenses);
});

export default function Noi() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>Net Operating Income</Title>
      <ResponsiveContainer>
        <BarChart
          data={data}
          barGap="0"
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <Legend verticalAlign="top" height={36} />
          <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label angle={270} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
              Income ($)
            </Label>
          </YAxis>

          <Bar fill="#82ca9d" dataKey="income"></Bar>
          <Bar fill="#ff0000" dataKey="expenses"></Bar>
          <Bar fill="#00101F" dataKey="noi">
            <LabelList dataKey="noi" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
