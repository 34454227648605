import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { formatNumberToCurrency } from '../../utils/dataParsing';
import { newPruynOperatingData } from '../../data/propertyData';

const npData = newPruynOperatingData;

function createData(id: number, date: string, income: number, expenses: number, mortgagePayment: number) {
  const noi = income - expenses;
  const cashFlow = noi - mortgagePayment;

  return { id, date, income, expenses, noi, mortgagePayment, cashFlow };
}

const rows = npData.map((item, index) => {
  return createData(index, item.month, item.income, item.expenses, item.mortgage);
});

function preventDefault(event: { preventDefault: () => void }) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function CashFlow() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Cash Flow</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Income</TableCell>
            <TableCell>Expenses</TableCell>
            <TableCell>NOI</TableCell>
            <TableCell>Mortgage Payment</TableCell>
            <TableCell>Cash Flow</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{formatNumberToCurrency(row.income)}</TableCell>
              <TableCell>{formatNumberToCurrency(row.expenses)}</TableCell>
              <TableCell>{formatNumberToCurrency(row.noi)}</TableCell>
              <TableCell>{formatNumberToCurrency(row.mortgagePayment)}</TableCell>
              <TableCell>{formatNumberToCurrency(row.cashFlow)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
